<template>
  <div class="modal-container">
    <div class="modal-wrapper">
      <div class="swiper-slide">
        <div class="swipe-card" v-if="status === 'success'">
          <div class="swipe-text text-center">
            <div>
              <div class="modal-icon-wrapper">
                <div class="p-5"><div class="ic suceess" /></div>
              </div>
              <h3>付費成功！</h3>
              <p>
                您的 PAMO 車禍線上律師會於24hr後生效。<br />
                如您需要立即使用車禍線上律師，請聯絡<a href="https://lin.ee/SbFLz7Y">PAMO客服人員</a
                >。
              </p>
            </div>
          </div>
          <div class="swipe-link-button clickable" @click="$router.push('/')">返回主選單</div>
        </div>
        <div class="swipe-card" v-if="status === 'failed'">
          <div class="swipe-text text-center">
            <div>
              <div class="modal-icon-wrapper">
                <div class="p-5"><div class="ic failed" /></div>
              </div>
              <h3>付費失敗！</h3>
              <p>
                您的信用卡付費驗證失敗，<br />請再確認卡片資訊是否正確，<br />或是洽詢卡片服務提供商
              </p>
            </div>
          </div>
          <a href="https://lin.ee/SbFLz7Y"
            ><div class="swipe-next-button clickable">聯絡客服</div></a
          >
          <div class="swipe-link-button clickable" @click="onClose">重新輸入付費資訊</div>
        </div>
        <div class="swipe-card" v-if="status === 'warning'">
          <div class="swipe-text text-center">
            <div>
              <div class="modal-icon-wrapper">
                <div class="p-5"><div class="ic warning" /></div>
              </div>
              <h3>您確定要取消綁卡？</h3>
              <p>如果會期中斷，點數將重新計算。</p>
            </div>
          </div>
          <div class="swipe-link-button clickable" @click="onClose">不要取消綁卡</div>
          <div class="swipe-next-button clickable" @click="onDeleteClick(cardId)">
            我確定要取消綁卡
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { payment } from '@/mixins';
import 'swiper/css/swiper.css';

export default {
  props: ['onClose', 'status', 'cardId'],
  mixins: [payment],
  components: {},
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapState({
      profile: (state) => state.auth.profile,
    }),
  },
  mounted() {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  },
  methods: {
    onDeleteClick(cardId) {
      console.log(cardId);
      this.deleteUserPayment({
        cardId,
        onSuccess: () => {
          this.$router.go();
        },
        onFailed: (err) => {
          switch (err.response.data.apiErrorCode) {
            case 'CARD_IS_ENABLED':
              alert('不能刪除正在使用中的卡。');
              break;
            default:
              alert('發生錯誤，請再試一次');
              break;
          }
        },
      });
    },
  },
  destroyed() {
    document.body.style.position = '';
    document.body.style.width = '';
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
    };
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
.modal-container {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.modal-wrapper {
  position: absolute;
  background: transparent;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  height: -webkit-fill-available;
  padding: 40px 10px 20px 10px;
  display: flex;
}

.swiper-slide {
  display: flex;
}

.swipe-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.swipe-close {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 25px;
  height: 25px;
  opacity: 0.8;
}

.swipe-close:before,
.swipe-close:after {
  position: absolute;
  left: 12px;
  content: ' ';
  height: 26px;
  width: 2px;
  background-color: #333;
}

.swipe-close:before {
  transform: rotate(45deg);
}
.swipe-close:after {
  transform: rotate(-45deg);
}

.modal-icon-wrapper {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}
.ic {
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &.suceess {
    background-image: url('../assets/ic_suceess.png');
  }
  &.failed {
    background-image: url('../assets/ic_failed.png');
  }
  &.warning {
    background-image: url('../assets/ic_warning.png');
  }
}

.swipe-img2 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_2@2x.png');
}

.swipe-img3 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_3@2x.png');
}

.swipe-img4 {
  width: 100%;
  height: 0;
  padding-top: 54.6%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/tutorial_4@2x.png');
}

.swipe-title {
  margin-top: 20px;
  padding: 0 20px;
  color: black;
  border-bottom: 1px solid grey;
}

.swipe-text {
  flex: 1;
  color: #7e7e7e;
  padding: 20px;
  min-height: 70px;
  overflow: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    color: black;
  }
}

.swipe-link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58px;
  background: #b49d5f 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #ffffff;
}

.swipe-next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58px;
  background: #404040 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #ffffff;
}
</style>
