<template>
  <div style="height: 100vh" class="d-flex flex-column" v-if="!requestingPayment">
    <div class="container flex-grow-1">
      <div class="wrapper h-100 d-flex flex-column">
        <!-- <Header title="成為PAMO會員" backto="/" /> -->
        <div class="head mb-5">
          <div class="justify-content-center row">
            <div class="col-lg-7">
              <img src="@/assets/registor_header_3.png" />
            </div>
          </div>
        </div>
        <div class="justify-content-center row">
          <div class="col-lg-7">
            <h6 style="color: #ff0000">必填欄位*</h6>
            <div class="row my-3">
              <div class="col">
                <label for="number">信用卡卡號<span class="required">*</span></label>
                <div class="form-control" autocomplete="off" ref="number"></div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <label for="cardExpirationDate">到期日<span class="required">*</span></label>
                <div class="form-control" autocomplete="off" ref="expirationDate"></div>
              </div>
              <div class="col">
                <label for="cardCcv">安全碼<span class="required">*</span></label>
                <div class="form-control" autocomplete="off" ref="ccv"></div>
              </div>
            </div>
            <div v-if="!profile.license || profile.license.orderId === null">
              <h6>優惠代碼</h6>
              <div class="row mb-3 align-items-center" id="coupon">
                <div class="col-lg-6">
                  <b-form-input id="coupon" v-model="coupon" :state="couponUsable" />
                </div>
                <div class="col" v-if="couponUsable != null">
                  <div class="unusable" v-if="!couponUsable">無效的優惠代碼</div>
                  <div class="usable" v-else>
                    <h5 class="mb-0">
                      <span><small>第一年享有</small></span>
                      <span v-show="discount_price">折扣 NT${{ discount_price }}</span>
                      <span v-show="discount_percentage"
                        >{{ (100 - discount_percentage) / 10 }}折優惠</span
                      >
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h6>付款金額</h6>
                <span class="total-amount">
                  <span>NT$</span>
                  <span v-if="couponUsable">{{
                    Math.floor(1200 - discount_price - (1200 * discount_percentage) / 100)
                  }}</span>
                  <span v-else>1200</span>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ol class="pl-3">
                  <li>會員資格將會於開通後24小時後生效</li>
                  <li>開通會員後24小時之後即可無限制免費使用車禍即時客服服務</li>
                  <li>開通會員後24小時內如需使用車禍即時客服服務仍須支付額外費用</li>
                  <li>開通會員後即可獲得1200點，點數可立即兌換法律服務</li>
                  <li>會員期間每年可獲得1200點</li>
                  <li>會員點數可每年累積，隨時兌換皆有效</li>
                  <li>會員期間不限次數使用線上和解書服務</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <a href="javascript:;" @click="$router.push('/plan/add')"
            ><img src="@/assets/banner_lawgod.png"
          /></a>
        </div>
        <div class="row">
          <div class="col text-center">
            <a href="javascript:;" @click="this.requestLogout"
              ><b-icon icon="caret-left-fill" aria-hidden="true"></b-icon>返回登入頁面</a
            >
          </div>
          <div class="col text-center">
            <a href="javascript:;" @click="$router.push('/')"
              >前往主選單<b-icon icon="caret-right-fill" aria-hidden="true"></b-icon
            ></a>
          </div>
        </div>
      </div>
    </div>
    <PayModal v-if="payModalShow" :onClose="closePayModal" :status="payModalState" />
    <div :class="{ disabled: loadingRequest }">
      <div class="bottom">
        <div class="d-flex">
          <div class="w-100" @click="$router.replace('/plan/add')">
            <Button name="使用序號開通"></Button>
          </div>
          <div class="w-100" @click="onSubmit">
            <Button class="highlight" name="付款並開通"></Button>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="loadingRequest" :can-cancel="false" :is-full-page="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { payment, auth } from '@/mixins';
import Button from '@/elements/Button.vue';
import Loading from 'vue-loading-overlay';
import PayModal from '@/components/PayModal.vue';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  mixins: [payment, auth],
  name: 'MakePayment',
  computed: {
    ...mapState({
      requestingOrderPlan: (state) => state.payment.requestingOrderPlan,
      requestingPayment: (state) => state.payment.requestingPayment,
      requestingBindPayment: (state) => state.payment.requestingBindPayment,
      payment: (state) => state.payment.payment,
      profile: (state) => state.auth.profile,
    }),
  },
  components: { Button, Loading, PayModal },
  data() {
    return {
      payModalShow: false,
      payModalState: '',
      step: 0,
      states: [],
      coupon: null,
      couponUsable: null,
      discount_percentage: 0,
      discount_price: 0,
      loadingRequest: false,
    };
  },
  watch: {
    coupon: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          if (this.coupon) {
            this.checkCoupon({
              code: this.coupon,
              onSuccess: (res) => {
                this.couponUsable = res.data.usable;
              },
              onFailed: () => {
                alert('發生錯誤，請再試一次');
              },
            });
            this.checkCouponDiscount({
              code: this.coupon,
              onSuccess: (res) => {
                this.discount_price = res.data.fixPrice;
                this.discount_percentage = res.data.percentageOff;
              },
              onFailed: () => {
                alert('發生錯誤，請再試一次');
              },
            });
          } else {
            alert('請先輸入折扣碼');
          }
        }
      },
    },
    payment: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.card = newVal.find((v) => v.enabled);
          this.cardText = `XXXX-XXXX-XXXX-${newVal.find((v) => v.enabled).cardLastFour}`;
        }
      },
    },
  },
  mounted() {
    TPDirect.setupSDK(
      '13886',
      'app_RcmJHxrLU1rnPfLLaKEx8zxDBesXZlcfM9aa5Uf4q5BCEwJOwcVVd9c0DDXC',
      process.env.VUE_APP_TAPPAY_ENV
    );

    const fields = {
      number: {
        element: this.$refs.number,
        placeholder: '**** **** **** ****',
      },
      expirationDate: {
        element: this.$refs.expirationDate,
        placeholder: 'MM/YY',
      },
      ccv: {
        element: this.$refs.ccv,
        placeholder: '安全碼',
      },
    };

    TPDirect.card.setup({
      fields,
      styles: {
        // Style all elements
        input: {
          color: 'gray',
        },
        // Styling ccv field
        'input.cvc': {
          'font-size': '16px',
        },
        // Styling expiration-date field
        'input.expiration-date': {
          // 'font-size': '16px'
        },
        // Styling card-number field
        'input.card-number': {
          // 'font-size': '16px'
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          color: 'green',
        },
        // style invalid state
        '.invalid': {
          color: 'red',
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          input: {
            color: 'orange',
          },
        },
      },
    });

    TPDirect.card.onUpdate((update) => {
      if (update.canGetPrime) {
        // 全部欄位皆為正確 可以呼叫 getPrime
        this.disabledBtnPay = false;
      } else {
        this.disabledBtnPay = false;
      }

      this.updateStatus(update.status.number);
      this.updateStatus(update.status.expiry);
      this.updateStatus(update.status.number);
    });
  },
  methods: {
    updatePayModalStatus(status) {
      this.payModalState = status;
      this.payModalShow = true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    updateStatus(field) {
      switch (field) {
        case 0:
          // 欄位已填好，並且沒有問題
          console.log('field is ok');
          break;
        case 1:
          // 欄位還沒有填寫
          console.log('field is empty');
          break;
        case 2:
          // 欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
          console.log('field has error');
          break;
        case 3:
          // 使用者正在輸入中
          console.log('usertyping');
          break;
        default:
          console.log('error!');
      }
    },
    validateAndEncodeURL(url) {
      try {
        return encodeURIComponent(url);
      } catch (e) {
        console.error('Invalid URL');
        return '';
      }
    },
    onSubmit() {
      this.loadingRequest = true;
      const tappayStatus = TPDirect.card.getTappayFieldsStatus();
      if (tappayStatus.canGetPrime === false) {
        // can not get prime
        this.loadingRequest = false;
        alert('信用卡資訊格式錯誤，請修正後再試一次');
        return;
      }
      // Get prime
      TPDirect.card.getPrime((result) => {
        if (result.status !== 0) {
          // get prime error
          console.log(result.msg);
          return;
        }

        const { prime } = result.card;

        if (this.profile.license === null || !this.profile.license.orderId) {
          console.log('付款綁卡');
          this.firstPurchase(prime);
        } else {
          console.log('只綁卡');
          // this.bindCard(prime);
          this.firstPurchase(prime);
        }
      });
    },
    firstPurchase(prime) {
      const encodedCoupon = this.coupon ? this.validateAndEncodeURL(this.coupon) : null;
      this.bindUserPayment({
        cardholderName: 'cardhloderName',
        prime,
        discountCode: encodedCoupon,
        onSuccess: () => {
          this.updatePayModalStatus('success');
          this.loadingRequest = false;
        },
        onFailed: (err) => {
          this.loadingRequest = false;
          switch (err.response.data.apiErrorCode) {
            case 'DUPLICATED_CARD':
              alert('此信用卡已經加入，無法重複新增相同卡片。');
              break;
            default:
              this.updatePayModalStatus('failed');
              break;
          }
        },
      });
    },
    bindCard(prime) {
      this.bindUserCard({
        cardholderName: 'cardhloderName',
        prime,
        onSuccess: () => {
          this.updatePayModalStatus('success');
          this.loadingRequest = false;
        },
        onFailed: (err) => {
          this.loadingRequest = false;
          switch (err.response.data.apiErrorCode) {
            case 'DUPLICATED_CARD':
              alert('此信用卡已經加入，無法重複新增相同卡片。');
              break;
            default:
              this.updatePayModalStatus('failed');
              break;
          }
        },
      });
    },
    getCardTypeText(cardType) {
      switch (cardType) {
        case '-1':
          return '未知的信用卡';
        case '1':
          return 'VISA 信用卡';
        case '2':
          return 'MasterCard 信用卡';
        case '3':
          return 'JCB 信用卡';
        case '4':
          return 'Union Pay 信用卡';
        case '5':
          return 'AMEX 信用卡';
        default:
          return '信用卡';
      }
    },
    closePayModal() {
      this.payModalShow = false;
    },
  },
};
</script>
<style>
.container {
  background-color: black;
  padding-bottom: 100px;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
}

.total-amount {
  font: normal normal bold 36px/44px Helvetica Neue;
  color: #ffffff;
}

.required {
  color: #ff0000;
  font-size: 24px;
}

.CouponDetailBox {
  height: 38px;
  margin-top: 25px;
  margin-left: -15px;
  display: flex;
  align-items: center;
  color: #b49d5f;
  font-size: 12px;
}

.unusable {
  color: #ff0000;
}

.usable {
  color: greenyellow;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.disabled {
  pointer-events: none;
}
</style>
